import React from "react";
import styles from './styles/donate.module.css';
import DonateqrImage from '../../assets/snehwan_QR_Code.jpg';
import DonationFromIndia from "./DonationFromIndia";
import InternationalDonation from "./InternationalDonation";

const DonateRM = () => {
  return (
    <div className={styles.donatecontainer}>
      <div className={styles.qrsection}>
        <img
          src={DonateqrImage}
          alt="QR Code for Donation"
          className={styles.imageContainer}
          loading="lazy"
        />
      </div>
      <div className={styles.donationInfoContainer}>
        <DonationFromIndia />
      </div>
      <hr className={styles.dividerBetween} />
      <div className={styles.donationInfoContainer}>
        <InternationalDonation />
      </div>
    </div>
  );
};
export default DonateRM;
