import React, { useState, useEffect, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable'; 
import styles from "../styles/gallerysubmodule.module.css";
import GalleryCard from '../../common/GalleryCard';
import { motion, AnimatePresence } from 'framer-motion';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
const National = () => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);

  useEffect(() => {
    fetch('/data/awards.json')
      .then((response) => response.json())
      .then((data) => {
        setPhotos(data.national);
      })
      .catch((error) => console.error('Error fetching photos:', error));
  }, []);

  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
  };

  const handleClose = () => setSelectedPhotoIndex(null);

  const handlePrev = () => {
    setSelectedPhotoIndex((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setSelectedPhotoIndex((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
  };

  const memoizedPhotos = useMemo(() => photos, [photos]);
  const loadingAnimation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5, delay: 0.2 },
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext, // Swipe left to go to the next image
    onSwipedRight: handlePrev, // Swipe right to go to the previous image
    trackMouse: true, // Enable mouse swipe for desktop
  });
  return <div className={styles.photos}>
         {memoizedPhotos.length > 0 ? (
        <motion.div
          className={styles['photo-gallery']}
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
          }}
        >
          {memoizedPhotos.map((photo, index) => (
            <div key={index} onClick={() => handlePhotoClick(index)}>
              <GalleryCard photo={photo} />
            </div>
          ))}
        </motion.div>
      ) : (
        <motion.p
          initial="hidden"
          animate="visible"
          variants={loadingAnimation}
        >
          Loading photos...
        </motion.p>
      )}
      <AnimatePresence>
        {selectedPhotoIndex !== null && (
          <motion.div
            className={styles.modalOverlay}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className={styles.modalContent} {...swipeHandlers}>
              <button className={styles.closeButton} onClick={handleClose}>
                ✕
              </button>
              <button className={styles.prevButton} onClick={handlePrev}>
                <MdNavigateBefore />
              </button>
              <img
                src={photos[selectedPhotoIndex]?.path}
                alt={photos[selectedPhotoIndex]?.title}
                className={styles.modalImage}
              />
              <button className={styles.nextButton} onClick={handleNext}>
                <MdNavigateNext />
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
  </div>;
};

export default National;
