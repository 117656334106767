import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import DonateRM from '../components/pagedata/donateRM';
import { useLocation } from 'react-router-dom';


const Donate = () => {
  const [readMore, setReadMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setReadMore(false);
  }, [location]);
  return (
    <div id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <MainDataShowup childComponent={DonateRM} />
          </motion.div>
        ) : (
          <MainInfoComponent
            heading="your donation"
            subheading="Make a Lasting Impact – Donate Today!"
            para={
              <>
                Your generosity has the power to <strong>transform lives of our children. </strong>
                At Snehwan, every contribution—no matter the size—plays a crucial role in helping us provide essential services and support to those in need.
                When you donate, you are not just giving money; you are <strong>investing in hope, opportunity, and a brighter future for children at Snehwan.</strong>
              </>
            }
            readMore="Donate now"
            onReadMore={() => setReadMore(true)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
export default Donate;
