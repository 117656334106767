import React from 'react'
import styles from './styles/privacypolicy.module.css'
const  PrivacyAndTerms = () => {
  return (
    <div className={styles.privacy}>
      <p>Please read through the below to understand your rights and responsibilities in using our website and services.</p>
      <p>Privacy policy  |  Terms & conditions  |  Refund Policy</p>
      <h4>Privacy Policy Overview</h4>
      <p>Snehwan respects your privacy. We collect information to provide services to you and update you on the impact of your contributions. Snehwan recognizes that we must maintain and use your information responsibly.</p>
      <p>​We collect information from you when you visit and take actions on our website. We use this information to provide the services you’ve requested. We may use cookies (such as Google Analytics) to provide a better experience and improve our website.</p>
      <p>​We will only use your information to keep you up to date with our work or answer your inquiries. We will not trade, share, or sell your personal data with anyone.</p>
      <p>If you would like to read or have any questions about our Privacy Policy, please contact us at Snehwan@yahoo.in.</p>
      <h4>Privacy Policy in Full</h4>
      <p>What we collect</p>
      <p>We only collect the personal information you voluntarily provide us via our website, email, or face-to-face.</p>
      <p>We collect information from you when you visit and take action on our website. We use this information to provide the services you’ve requested.</p>
      <p>Cookies</p>
      <p>We use cookies (such as Google Analytics) to provide a better experience and improve our website. If you wish to delete any cookies that are already on your computer, please refer to the instructions for your file management software to locate the file or directory that stores cookies.</p>
      <p>Information on deleting or controlling cookies is available at www.AboutCookies.org. Please note that by deleting our cookies or disabling future cookies you may not be able to access certain areas or features of our site.</p>
      <p>How we use it</p>
      <p>We will only use your information to keep you up to date with our work or answer and manage your inquiries. We will not trade, share, or sell your personal data with anyone.</p>
      <p>When you submit a volunteer inquiry form we retain your information to allow us to contact you about relevant opportunities.</p>
      <p>Security</p>
      <p>We understand that your data is important and private, so we work hard to keep it secured. It is saved in password-protected databases and customer relationship management systems only available to authorized Snehwan staff access it for business use only.</p>
      <h4>Online payments</h4>
      <p>All of our online payments are processed through NEFT and RTGS or Google Pay. We only use this information to issue receipts for your donations and, where relevant and you have requested them, updates from the projects you are supporting.</p>
      <h4>Your rights</h4>
      <p>You have the right to request we remove you from our mailing lists. You can do this by unsubscribing to our mailing list via our website homepage, www.snehwan.in, or by emailing Snehwan@yahoo.in</p>
      <p>You retain the right to have data corrected if it is wrong, to prevent users that are causing you damage or distress, or to stop marketing information from being sent to you.</p>
      <p>You also have the right to request any information we hold about you. If you would like to request this please email Snehwan@yahoo.in and we will provide the relevant information within one month.</p>
      <p>Terms & Conditions</p>
      <p>The terms “We” / “Us” / “Our”/”Trust” individually and collectively refer to Snehwan and the terms “Visitor” and”User” refer to the users.</p>
      <p>This page states the Terms and Conditions under which you (Visitor) may visit this website www.snehwan.in. Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this site. The organization, any of its divisions, and/or its subsidiaries and associate organizations (in India or abroad) reserve their respective rights to revise these Terms and Conditions at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the Terms and Conditions because they are binding on all users of this Website.</p>
      <h4>USE OF CONTENT</h4>
      <p>All logos, brands, marks headings, labels, names, signatures, numerals, shapes, or any combinations thereof, appearing on this site, except as otherwise noted, are properties either owned or used under license, by the organization and/or its associate entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these Terms and Conditions or in the site content, is strictly prohibited.</p>
      <p>You may not sell or modify the content of this website or reproduce, display, publicly perform, distribute, or otherwise use the materials in any way for any public or commercial purpose without the respective organization’s or entity’s written permission which you can request by emailing Snehwan@yahoo.in</p>
      <h4>ACCEPTABLE WEBSITE USE</h4>
      <p>Security Rules</p>
      <p>Visitors are prohibited from violating or attempting to violate the security of the website, including, without limitation,</p>
      <p>Accessing data not intended for such user or logging into a server or account, which the user is not authorized to access,</p>
      <p>Attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization,</p>
      <p>Attempting to interfere with service to any user, host, or network, including, without limitation, via means of submitting a virus or “Trojan horse” to the Website, overloading, “flooding”, “mail bombing” or “crashing”, or</p>
      <p>Sending unsolicited electronic mail, including promotions and/or advertising of products or services. Violations of system or network security may result in civil or criminal liability.</p>
      <p>The organization and/or its associate entities will have the right to investigate occurrences that they suspect as involving such violations and will have the right to involve and cooperate with, law enforcement authorities in prosecuting users who are involved in such violations.</p>
      <h4>General Rules</h4>
      <p>Visitors may not use the Web Site in order to transmit, distribute, store or destroy material:</p>
      <p>That could constitute or encourage conduct that would be considered a criminal offense or violate any applicable law or regulation,</p>
      <p>In a manner that will infringe the copyright, trademark, trade secret, or other intellectual property rights of others or violate the privacy or publicity of other personal rights of others, or</p>
      <p>That is libelous, defamatory, pornographic, profane, obscene, threatening, abusive, or hateful.</p>
      <h4>INDEMNITY</h4>
      <p>The User unilaterally agrees to indemnify and hold harmless, without objection, the Company, its officers, directors, employees, and agents from and against any claims, actions and/or demands and/or liabilities and/or losses and/or damages whatsoever arising from or resulting from their use of www.snehwan.in or their breach of the terms.</p>
      <h4>LIABILITY</h4>
      <p>User agrees that neither organization nor its associates, secretary, officers, or employees shall be liable for any direct/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages, resulting from the use or/and the inability to use the service or/and for the cost of procurement of substitute goods or/and services or resulting from any goods or/and data or/and information or/and services purchased or/and obtained or/and messages received or/and transactions entered into through or/and from the service or/and resulting from unauthorized access to or/and alteration of user’s transmissions or/and data or/and arising from any other matter relating to the service, including but not limited to, damages for loss of profits or/and use or/and data or other intangible, even if the organization has been advised of the possibility of such damages.</p>
      <p>User further agrees that Snehwan shall not be liable for any damages arising from interruption, suspension, or termination of service, including but not limited to direct or/and indirect or/and incidental or/and special consequential or/and exemplary damages, whether such interruption or/and suspension or/and termination was justified or not, negligent or intentional, inadvertent or advertent.</p>
      <p>The user agrees that Snehwan shall not be responsible or liable to the user, or anyone, for the statements or conduct of any third party of the service. In sum, in no event shall trust’s total liability to the User for all damages or/and losses or/and causes of action exceed the amount paid by the User to trust, if any, that is related to the cause of action.</p>
      <h4>DISCLAIMER OF CONSEQUENTIAL DAMAGES</h4>
      <p>In no event shall Snehwan or any parties, organizations, or entities associated with the corporate brand name use or otherwise, mentioned on this website be liable for any damages whatsoever (including, without limitations, incidental and consequential damages, lost profits, or damage to computer hardware or loss of data information or business interruption) resulting from the use or inability to use the website and the website material, whether based on warranty, contract, tort, or any other legal theory, and whether or not, such organization or entities were advised of the possibility of such damages.</p>
      <h4>REFUND POLICY</h4>
      <p>As a charitable, non-profit organization, Snehwan is unable to refund donations after the donation is accepted.</p>
      <p>No cash or refund of money will be allowed.</p>
      <p>Once received the donation for a cause will not be refunded to the donor. No cancellation will be made.</p>
      <p>Unless a specified donation purpose is selected at the time of donation, funds will be unrestricted and used to empower and educate children as per our charitable purposes.</p>
      <p>If you have any questions about any of our terms or policies, please contact us at snehwan@yahoo.in</p>
    </div>
  )
}

export default  PrivacyAndTerms