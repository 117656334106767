import React from 'react';
import { motion } from 'framer-motion';
import styles from './styles/home.module.css';

const Home = () => {
  return (
    <div className={styles.home}>
      <div>
        <motion.h2
          initial={{ opacity: 0, y: -20 }} 
          animate={{ opacity: 1, y: 0 }}  
          transition={{duration: 1, delay: 0.6 }}    
        >
          welcome to snehwan
        </motion.h2>
        <motion.h2
          initial={{ opacity: 0, y: -20 }} 
          animate={{ opacity: 1, y: 0 }}  
          transition={{ duration: 1.1, delay: 0.3 }}    
        >
          be the change you want to see in the world
        </motion.h2>
      </div>
      <div>
        <motion.h3
          initial={{ opacity: 0, y: -20 }} 
          animate={{ opacity: 1, y: 0 }}  
          transition={{ duration: 1, delay: 0.3 }}    
        >
          Snehwan was established as a result of one man's commitment to offer food, shelter, and quality education to underprivileged children. Launched by Ashok Deshmane in 2015 with just 18 children, this social initiative has now grown into a loving home for 180 children, affectionately known as Snehwan.
        </motion.h3>
        
      </div>
    </div>
  );
};

export default Home;