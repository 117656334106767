import React from 'react';
import styles from '../styles/contact.module.css';

const ContactForm = ({
  formData = {},
  handleChange,
  handleSubmit,
  isSubmitting,
  statusMessage,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <label className={styles.label}>Name -</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Email -</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Contact -</label>
            <input
              type="tel"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Address -</label>
            <textarea
              name="address"
              value={formData.address}
              onChange={handleChange}
              rows="3"
              className={styles.textarea}
              required
            ></textarea>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>Message -</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="2"
              className={styles.textarea}
              required
            ></textarea>
          </div>
          {statusMessage.message && (
            <div className={styles.formGroup}>
              <div className={styles.label}></div>
              <p
                className={`${styles.statusMessage} ${
                  statusMessage.type === 'success'
                    ? styles.success
                    : styles.error
                }`}
              >
                {statusMessage.message}
              </p>
            </div>
          )}

          <div className={styles.formGroup}>
            <div className={styles.label}></div>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'sending...' : 'submit'}
            </button>
          </div>
        </form>
      </div>

      <div className={styles.mapSection}>
        <p className={styles.mapTitle}>FIND US ON MAP</p>
        <iframe
          className={styles.map}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.679062144006!2d73.94898727344861!3d18.72318136295734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c79be0fb8abd%3A0x1cd6dae5dea9d544!2sSnehwan%20-%20Official!5e0!3m2!1sen!2sin!4v1735717034319!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps Embed"
        ></iframe>
        <div className={styles.address}>
          <p>
            <b>Snehwan,</b> Near Koyali Phata, Koyali Forest Koyali tarfe Chakan
            Ta. Khed, Dist. <b>Pune 410501</b>
          </p>
          <p>
            Maharashtra, India.
            <b>
              {' '}
              M: <a href="tel:+918796400484">+91 8796400484</a>
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
