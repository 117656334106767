import React from 'react';
import style from './footer.module.css';
import { NavLink } from 'react-router-dom';
const Footer = () => {
  
  return (
    <div className={style.footer}>
      <div className={style.social_icons}>
        <div className={style.line}></div>
        <div className={style.social_icons_images}>
        <a href="https://www.facebook.com/snehwan" target="_blank" rel="noopener noreferrer">
            <img src="/assets/fb.png" alt="Facebook" loading="lazy" />
          </a>
          <a href="https://www.instagram.com/snehwan" target="_blank" rel="noopener noreferrer">
            <img src="/assets/insta.png" alt="Instagram" loading="lazy" />
          </a>
          <a href="https://wa.me/+918796400484" target="_blank" rel="noopener noreferrer">
            <img src="/assets/whats.png" alt="WhatsApp" loading="lazy" />
          </a>
        
          <a href="https://www.youtube.com/@Snehwan/videos" target="_blank" rel="noopener noreferrer">
            <img src="/assets/youtube.png" alt="YouTube" loading="lazy" />
          </a>
          <a href="https://www.linkedin.com/company/snehwan/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/linked.png" alt="LinkedIn" loading="lazy" />
          </a>
          <a href="https://x.com/snehwan" target="_blank" rel="noopener noreferrer">
            <img src="/assets/twitter.png" alt="X" loading="lazy" />
          </a>
        </div>
        <div className={style.line}></div>
      </div>
      <div className={style.footer_address}>
        <div>
          <p><strong>Snehwan</strong>, Near Koyali Phata, Koyali tarfe Chakan Ta. Khed, Dist. <strong>Pune 410501.</strong> </p>
        </div>
        <div>
        <p>Maharashtra, India.<strong> M: +91 8796400484 </strong></p>
        </div>
      </div>
      <div className={style.line_and_diff}></div>
      <div className={style.copywrite_section}>
      <div></div>
        <div>
          <p>Copyrights © 2025 SNEHWAN. All rights reserved. |<NavLink to="privacy-terms"> Privacy & Terms</NavLink></p>
        </div>
        <div>
        website designed and maintained by &nbsp;
        <a href="https://ftbcolab.com/" target="_blank" rel="noopener noreferrer">
        FTB COLAB
        </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
