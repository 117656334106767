import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './styles/about.module.css';
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import AboutComponent from '../components/pagedata/AboutComponent';
import { useLocation } from 'react-router-dom';

const About = () => {
  const [readMore, setReadMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setReadMore(false);
  }, [location]);

  return (
    <div className={styles.about_page} id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <MainDataShowup childComponent={AboutComponent}/>
          </motion.div>
        ) : (
            <MainInfoComponent
              heading="about us"
              subheading="ABOUT FOUNDER"
              readMore="read more"
              onReadMore={() => setReadMore(true)}
              para={
                <>
          
                The ever-increasing global warming, natural calamities such as droughts, and floods are making our farmers’ lives extremely miserable. 
                The hope against hope for a long time has led to dire desperation, resulting in increased deaths of these food-, health- and nutrition-givers.
               
                </>
              }
             
            />
        )}
      </AnimatePresence>
    </div>
  );
};
export default About;