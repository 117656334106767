
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainHeader from './components/nav/MainHeader';
import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import BackgroundProvider from './components/BackgroundProvider';
import Award from './pages/Award';
import Donate from './pages/Donate';
import Document from './pages/Document';
import GetInvolved from './pages/GetInvolved';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';


function App() {
  return (
    <>
      <BrowserRouter>
        <BackgroundProvider>
          <Routes>
            <Route path="/" element={<MainHeader />}>
              <Route index element={<Navigate to="home" />} />
              <Route path="home" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="gallery/*" element={<Gallery />} />
              <Route path="award/*" element={<Award />} />
              <Route path="donate" element={<Donate />} />
              <Route path="document" element={<Document />} />
              <Route path="get-involved/*" element={<GetInvolved />} />
              <Route path="contact" element={<Contact />} />
              <Route path="privacy-terms" element={<Privacy />} />
              <Route path="*" element={<Home />} />
            </Route>
          </Routes>
        </BackgroundProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
