import React from 'react';
import Navbar from './Navbar';
import styles from './navbar.module.css';
import { Outlet } from 'react-router-dom';
import Footer from '../footer/Footer';
import { motion } from 'framer-motion'; 
const MainHeader = () => {
  return (
    <>
      <motion.div
      className="wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Navbar />
      <div className={styles.mainContent}>
        <div className={styles.main_outlet}>
          <Outlet />
        </div>
    
      </div>
      </motion.div>
    <Footer />
    </>
  );
};

export default MainHeader;
