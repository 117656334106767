import React from 'react'
import styles from "../pagedata/styles/gallerysubmodule.module.css";
import { motion } from 'framer-motion';
const GalleryCard = ({ photo }) => {
    const cardVariants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1 },
      };
  return (
    <motion.div
    className={styles['photo-item']}
    initial="hidden"
    animate="visible"
    variants={cardVariants}
    >
     {photo.type === 1 ? (
        <div className={styles['video-container']}>
          <iframe
            width="100%"
            height="280"
            src={`${photo.path}?modestbranding=1&autoplay=0`}
            title={photo.title}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : (
      <img src={photo.path} alt={photo.title} loading="lazy"/>
    )}
      <h3>{photo.title}</h3>
      <p>{photo.description}</p>
     </motion.div>
  )
}

export default GalleryCard