import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import homeBackground from '../assets/backgorund/home.jpg';
import aboutBackground from '../assets/backgorund/aboutus.jpg';
import galleryBackground from '../assets/backgorund/gallery.jpg';
import awardsBackground from '../assets/backgorund/awards.jpg';
import donateBackground from '../assets/backgorund/donate.jpg';
import documentBackground from '../assets/backgorund/document.jpg';
import involvedBackground from '../assets/backgorund/involved.jpg';
import contactusBackground from '../assets/backgorund/contact.jpg';

const BackgroundProvider = ({ children }) => {
  const location = useLocation();
  const [imagesLoaded, setImagesLoaded] = useState(false);

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useEffect(() => {
    // const body = document.body;
    const body = document.querySelector('.wrapper')
    const backgroundImages = {
      '/home': homeBackground,
      '/about': aboutBackground,
      '/gallery': galleryBackground,
      '/award': awardsBackground,
      '/donate': donateBackground,
      '/document': documentBackground,
      '/get-involved': involvedBackground,
      '/contact': contactusBackground
    };
    const basePathRegex = /^\/(home|about|gallery|award|donate|document|get-involved|contact)/;
    const match = location.pathname.match(basePathRegex);
    const basePath = match ? `/${match[1]}` : null;
    const currentImage = backgroundImages[basePath] || homeBackground;
    if (currentImage !== 'none') {
      body.style.backgroundImage = `url("${currentImage}")`;
      if (location.pathname === '/home') {
        body.style.backgroundPosition = 'bottom center'; 
      }
      else {
        body.style.backgroundPosition = 'center';
      }
    }
    if (!imagesLoaded) {
      const imagePromises = Object.values(backgroundImages).map((src) =>
        preloadImage(src)
      );

      Promise.all(imagePromises)
        .then(() => {
          setImagesLoaded(true);
          console.log("loading")
        })
        .catch((error) => console.error('Error loading images', error));
    }

    return () => {
      body.style.backgroundImage = 'none';
      body.style.backgroundPosition = 'center';
      body.classList.remove('fade-background'); 
    };
  }, [location, imagesLoaded]);

  return <>{children}</>;
};

export default BackgroundProvider;