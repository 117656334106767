import React from 'react';
import styles from './styles/awardrm.module.css';
import india from '../../assets/award/india.png';
import international from '../../assets/award/international.png';
import state from '../../assets/award/state.png';
import { useNavigate } from 'react-router-dom';
const AwardRM = () => {
    const navigate = useNavigate();
    const goPathDetails = (path="/state")=>{
      navigate(path);
    }
  return (
    <div className={styles.awardrm}>
      <div
        className={styles.award_item}
        onClick={() => goPathDetails('state')}
      >
        <img
          src={state}
          alt="State award"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >STATE<br /> AWARDS</p>
      </div>

      <div
        className={styles.award_item}
        onClick={() => goPathDetails('india')}
      >
        <img
          src={india}
          alt="Video content"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >NATIONAL<br /> AWARDS</p>
      </div>
      <div
        className={styles.award_item}
        onClick={() => goPathDetails('international')}
      >
        <img
          src={international}
          alt="Media coverage"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >INTERNATIONAL <br />AWARDS</p>
      </div>
    </div>
  );
};

export default AwardRM;
