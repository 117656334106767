import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './navbar.module.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../assets/logo.png'
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const menuItems = [
    { path: '/home', label: 'HOME' },
    { path: '/about', label: 'ABOUT' },
    { path: '/gallery', label: 'GALLERY' },
    { path: '/award', label: 'AWARD' },
    { path: '/get-involved', label: 'GET INVOLVED' },
    { path: '/donate', label: 'DONATE' },
    { path: '/document', label: 'DOCUMENT' },
    { path: '/contact', label: 'CONTACT' },
  ];

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLogo}>
      <NavLink
              to="/home">
        <img
          src={logo}
          alt="Logo"
          className={styles.logo}
          loading="lazy"
        />
        </NavLink>
      </div>
      <div className={styles.hamburger}>
      {menuOpen ? (
        <FaBars  onClick={toggleMenu} size="1.5rem" color="var(--nav-letter)" fontWeight="200"/>
      ) : (
        <div className={styles.navbarMenuOpen}>
        <div>
        <FaTimes  onClick={toggleMenu} size="1.5rem" color="var(--nav-letter)" fontWeight="200"/>
        </div>
        {menuItems.map((item, index) => (
          <React.Fragment key={item.path}>
            <NavLink
              to={item.path}
              onClick={toggleMenu} 
              className={({ isActive }) =>
                isActive ? `${styles.active} ${styles.link}` : styles.link
              }
            >
              {item.label}
            </NavLink>
            {index < menuItems.length - 1 && (
              <div className={styles.vetical_line} aria-hidden="true" />
            )}
          </React.Fragment>
        ))}
        </div>
      )}
      </div>
      <div
        className={styles.navbarMenu}
        role="navigation"
        aria-label="Main menu"
      >
        {menuItems.map((item, index) => (
          <React.Fragment key={item.path}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                isActive ? `${styles.active} ${styles.link}` : styles.link
              }
            >
              {item.label}
            </NavLink>
            {index < menuItems.length - 1 && (
              <div className={styles.vetical_line} aria-hidden="true" />
            )}
          </React.Fragment>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
