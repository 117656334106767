import React from 'react';
import CSR_image from '../../../assets/get_involved/csr_info.jpg';
import styles from '../styles/csrpage.module.css';

const Csrpage = () => {
  return (
    <div className={styles.csrpage}>
      <div className={styles.csr_module}>
        <div><h2>CSR WITH SNEHWAN</h2></div>
        <div className={styles.csr_module_info}>
          <div>
            <p>Help us through your company Corporate Social Responsibility</p>
            <p>The majority of our funds come from individuals who believe in our mission, and we do not receive government funding. To ensure consistent and sustainable service, we are seeking either short-term, one-time projects or long-term partnerships with corporates. Our commitment to transparency allows us to account for all achievements on behalf of our children. We would be honored to visit your organization and engage in a discussion about potential collaboration.</p>
            <p>It will be our pleasure to visit your organization for a tête-à-tête session about our work, and how you can help us.</p>
            <p>You can also visit our organization in Pune.</p>
            <p>Call 8237277615 or email snehwan@yahoo.in</p>
            <p><strong>Tax exemptions are available to all Indian tax payers under 80G.</strong></p>
            <p>To receive your tax receipt please email snehwan@yahoo.in with details of your Name, PAN, payment made (full amount paid and the account you paid it into), email ID, full postal address and Contact number. Once received, we will issue your receipt via email and post.</p>
          </div>
          <div>
            <img 
              src={CSR_image} 
              alt="CSR information about Snehwan's initiatives" 
              loading="lazy" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Csrpage;