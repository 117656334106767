import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles/aboutcomponent.module.css';
import children_playing from '../../assets/about/DSC00099-min.jpg';
import children_playing_carem from '../../assets/about/DSC00105-min.jpg';
import sir_teaching from '../../assets/about/DSC00158-min-480x320.jpg';
import about_founder from '../../assets/about/about-founder.jpg';
import swamivivikanand from '../../assets/about/projects/image-1080x812.png';
import vanchitgram from '../../assets/about/projects/Vanchit-Gram-1.jpg';
import gaushala from '../../assets/about/projects/Gaushala-5-300x204.jpg';
import computer_lab from '../../assets/about/projects/Computer-Lab-1-300x200.jpg';
import early_life from '../../assets/about/20190407_090900-1.jpg';
import ignited_mind from '../../assets/about/20190111_185822-1.jpg';
import thebeginning from '../../assets/about/the-beginning.jpg';
import thejourney from '../../assets/about/20190408_154920-1.jpg';
import pillar from '../../assets/about/pillar-4.jpg';
import family6 from '../../assets/about/projects/family6-300x200.jpg';
import Dnyanchatra from '../../assets/about/projects/Dnyanchatra-3-1280x868.jpg';
import Open_Library from '../../assets/about/projects/Done-480x360.jpg';
import Mobile_hospital from '../../assets/about/projects/Mobile-Hospital-768x522.jpg';
import Savitri_Woomen_Power from '../../assets/about/projects/Women-Empowerment-480x361.jpeg';
import Education_For_Elders from '../../assets/about/projects/Screenshot-2023-12-17-at-11.46.37-e1702794362811.png';
import Global_Classroom from '../../assets/about/projects/Teresa-480x412.jpg';
import Sugar_School from '../../assets/about/projects/Screenshot-2024-01-07-at-15.51.45-480x340.png';
import Container_Lib from '../../assets/about/projects/container_lib.jpg';
import Music_room from '../../assets/about/projects/IMG_4128-1-2048x1536.jpeg';
import Computer_lab from '../../assets/about/projects/Computer-Lab-1-300x200.jpg';
import Sewing_machine from '../../assets/about/projects/training-centre-480x361.jpeg';
import Physics_chem_lab from '../../assets/about/projects/IMG_3932-1-480x270.jpeg';
import Container_classroom from '../../assets/about/projects/Container-classroom-480x361.jpeg';
import Art_and_craft from '../../assets/about/projects/IMG_4129-1-300x225.jpeg';
import One_movie_one_book from '../../assets/about/projects/one_book_one_movie.jpg';
import Inspirational_visit from '../../assets/about/projects/inspirational-visit-300x216.jpg';
import Art_and_craft_1 from '../../assets/about/projects/art-300x216.jpg';
import Educational_tour from '../../assets/about/projects/educational-tour-300x225.jpg';
import Circle_ringan from '../../assets/about/projects/Ringan-300x216.jpg';
import Skill_Dev from '../../assets/about/projects/skill-development-300x216.jpg';
import Live_eat_together from '../../assets/about/projects/dinner-300x168.jpg';
import Music_live from '../../assets/about/projects/music-300x216.jpg';
import yoga from '../../assets/about/projects/yoga-480x360.jpeg';
import kitchen from '../../assets/about/projects/kitchen-980x1303.jpeg';
import Eco_friendly from '../../assets/about/projects/Eco-Friendly-1280x868.jpg';
import GalleryCard from '../common/GalleryCard';
import CountUp from 'react-countup';
import { useInView } from "framer-motion";
const AboutComponent = () => {
  const [numbersData, setNumbersData] = useState([]);
  const [committee, setCommittee] = useState([]);
  const [board, setBoard] = useState([]);

  useEffect(() => {
    fetch('/data/about.json')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setNumbersData(data.numbers_data || []);
        setCommittee(data.committee || []);
        setBoard(data.board || []);
      })
      .catch((error) => console.error('Error fetching photos:', error));
  }, []);

  const memoizedNumbersData = useMemo(() => numbersData, [numbersData]);
  const memoizedCommittee = useMemo(() => committee, [committee]);
  const memoizedBoard = useMemo(() => board, [board]);
  const ref = useRef(null); 
  const isInView = useInView(ref, { once: true }); 
  return (
    <div className={styles.about_component}>
      <div>
        <div className={styles.about_component_we_are_snehwan}>
          <div>
            <h2>We are SNEHWAN</h2>
            <p>
              The ever-increasing global warming, natural calamities such as
              droughts, and floods are making our farmers’ lives extremely
              miserable. The hope against hope for a long time has led to dire
              desperation, resulting in increased deaths of these food-, health-
              and nutrition-givers.
            </p>
            <p>
              Does this story end here, i.e., with the suicide? What about the
              families and children the farmers leave behind? They are left to
              struggle in the darkness, having no hope of a good life or a
              future!
            </p>

            <p>
              Don’t these kids deserve love, care, and safety?
              <br />
              Don’t they have the right to decent education and a normal life?
              <br />
              We, at Snehwan, think they deserve everything as much as every
              other child!
            </p>
            <p>
              Snehwan is a non-profit organization founded by Ashok Deshmane in
              December 2015. It is based near Alandi, Pune. Our aim is to create
              a nurturing and inclusive learning environment for the less
              fortunate children of farmers and poor families.
            </p>
            <p>
              Snehwan is a movement for those children who are deprived of
              learning due to adverse situations and surroundings. We believe
              education is a gift of life; it is the only way to improve the
              life of underprivileged children, forever.
            </p>
            <p>
              Along with quality education and schooling, we guide and prepare
              these children for competitive exams. We ensure they trot on the
              right path, know their rights and responsibilities, seek
              opportunities, fight injustice and also, develop leadership
              qualities. ALL FOR THEIR BETTER FUTURE!
            </p>
          </div>
          <div>
            <img
              src={children_playing}
              alt="children plyaing game"
              loading="lazy"
            />
            <img
              src={children_playing_carem}
              alt="children plyaing carem"
              loading="lazy"
            />
            {/* <img src={sir_teaching} alt="Sir Teaching" loading="lazy" /> */}
            {/* <img src={boyesplaying} alt="children plyaing carem" loading="lazy" /> */}
          </div>
        </div>
        <div className={styles.number_counting_strip} ref={ref}>
          {memoizedNumbersData.map((item, index) => (
            <div key={index} className={styles.counting_item}>
            {isInView && (
              <h1 className={styles.counting_value}>
              <CountUp 
              start={0} 
              end={parseInt(item.value.replace(/[^0-9]/g, ""), 10)} 
              duration={5} 
            /> +
              {/* {item.value} */}
              </h1>
            )}
              <p className={styles.counting_key}>{item.key}</p>
            </div>
          ))}
        </div>
        <div>
        <h4>ADVISORY COMMITTEE OF SNEHWAN</h4>
        </div>
        <div className={styles.committe}>
          {memoizedCommittee.map((item, index) => (
            <div key={index} className={styles.counting_item}>
              <GalleryCard photo={item} />
            </div>
          ))}
        </div>
        <div>
        <h4>BOARD OF DIRECTOR-SNEHWAN</h4>
        </div>
        <div className={styles.committe}>
          {memoizedBoard.map((item, index) => (
            <div key={index} className={styles.counting_item}>
              <GalleryCard photo={item} />
            </div>
          ))}
        </div>
        <hr className={styles.divider_in_about} />
        <div>
          <h2>History of SNEHWAN</h2>
          <div className={styles.about_container}>
            <div>
              <h4>ABOUT FOUNDER</h4>
              <p>
                Born in the Parbhani district of Marathwada, Ashok Deshmane grew
                up in abject poverty and hunger. Ashok and his family endured
                years of uncertainty and hardship, as did the other families of
                the drought-ravaged farming community. But that did not dampen
                his spirit. He got himself an education and a job, which changed
                the course of his life!
              </p>

              <p>Today, he harbors an undying desire to help others in need.</p>
            </div>
            <div>
              <img
                src={about_founder}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>EARLY LIFE</h4>
              <p>
                He had to work throughout his college life as his parents were
                unable to provide for college fees. He used to work in a garage
                and sleep in lorries to earn money for his education. Not only
                that, but he still remembers how the drivers from Andhra Pradesh
                shared their meals with him!
              </p>
              <p>
                Determined to make a difference, Ashok set out to get an
                education, and after years of hard work, he graduated with a
                Masters in Computer Science. This earned him a well-paying IT
                job in Pune!
              </p>
            </div>
            <div>
              <img
                src={early_life}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>IGNITED MIND</h4>
              <p>
                From a young age, Ashok channeled his thoughts into poetry,
                often focusing on the issues faced by farmers due to drought. He
                was still affected by news of the worsening conditions in his
                hometown. The constant news and updates from the drought in his
                village and the increasing number of suicides among farmers
                worried him.
              </p>
              <p>
                He went to his hometown, only to see the children abandoned on
                the streets, starving, and unaware of the happenings in the
                world. He soon realized that poetry alone could not solve the
                problems that his community faced.
              </p>
              <p>
                2014–2015 was a rough time for drought-affected families, and
                this was directly impacting their children’s education. “When
                there is no food to put in the stomach, what’s the point of
                putting anything in the brain?” was their attitude!
              </p>
              <p>
                Ashok had to do something about it! He started teaching local
                kids over the weekends, but unfortunately, their families had to
                move to a different town. He was heartbroken and decided that he
                would create a home for these children for their all-round
                development.
              </p>
            </div>
            <div>
              <img
                src={ignited_mind}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>THE BEGINNING</h4>
              <p>
                With this compassionate spirit, he embarked on a noble journey
                by founding an NGO named Snehwan in December 2015. Aiming to
                make a meaningful impact, he dedicated himself to the cause of
                educating children whose families were affected by the tragic
                circumstances of farmer suicides. In the initial phase, the
                initiative reached out to 18 children hailing from four
                districts in Maharashtra. Displaying a heartwarming commitment,
                his parents, who resided in Parbhani, stood by his side to offer
                unwavering support. His father, contributing his skills, taught
                the children the art of playing the Tabla, while his mother
                selflessly prepared nutritious meals for the entire group,
                creating a nurturing environment for these young minds.
              </p>
            </div>
            <div>
              <img src={thebeginning} alt="THE BEGINNING" loading="lazy" />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>THE JOURNEY</h4>
              <p>
                According to Ashok’s vision, if we want the next generation of
                farmers to do something substantial in the near future, it is
                necessary to educate them. And just basic education is not
                enough. Teaching them various subjects like computer science,
                yoga, music, dance, painting, etc. is needed. Children must be
                taught according to their talents.
              </p>
              <p>
                He felt if he had the will, he would find the way. He continued
                to work towards setting up Snehwan, along with night shifts on
                the job, for 9 months.
              </p>
              <p>
                Ashok began by donating a part of his salary to charity, but
                realized that this too wasn’t enough. He made the decision to
                dedicate his time and effort to helping the children in his
                community. He quit his high-paying IT job and devoted his whole
                time to educating the children.
              </p>
              <p>
                Ashok got married at the age of 25 in November 2016, and his
                wife, Archana, at the age of 21, was more than happy to know
                about his cause and contribute to it. She cooks meals regularly
                for the one large family they have and is like a mother to them.
                They live as one big happy family, along with around 25 young
                boys, in a four-room home. They also started to provide
                education and development programs for 27 girls from the nearest
                slum area.
              </p>
            </div>
            <div>
              <img src={thejourney} alt="THE JOURNEY" loading="lazy" />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>HELPING HANDS</h4>
              <p>
                Ashok and Archana were looking to increase the size of their
                family by bringing in more and more children to their little
                haven near Pune. But they wanted to expand and were looking for
                affordable land nearby. Having seen the same poverty as these
                children, he wanted them to learn more and, most of all, become
                better human beings.
              </p>
              <p>
                In 2018, Dr. Smita Kulkarni and Dr. Ravindra Kulkarni donated
                two acres of land to Snehwan. The late Nalini Kulkarni Dnyangram
                project was started on this land. There was only one aim: caring
                for and educating needy farmers’ children, with an emphasis on
                their holistic development. The Snehwan program, which has been
                run at Bhosari since 2015, has shifted to a new location in
                Koyali.
              </p>
            </div>
            <div>
              <img src={pillar} alt="THE BEGINNING" loading="lazy" />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>THE ROADMAP TO FUTURE</h4>
              <p>
                Ashok has no plans to slow down as he expands his efforts to
                other areas, such as agriculture. He is worried about the
                decline in interest in agrarian jobs and believes that if this
                trend continues in India, we may soon have to import food grains
                from China. He is laser-focused on building a school for
                agriculture, since most of the children are from farming
                backgrounds.
              </p>
              <p>
                Today, Ashok and his wife Archana are working in Snehwan to heal
                the scars of the trauma of losing loved ones and the physical
                effects of extreme poverty. Alongside, attempts to get them into
                mainstream society are ongoing. However, we propose to help
                these children realize their full potential and dreams by
                supporting higher education and providing professional training.
                But that is for the future. Today is for healing, caring,
                educating, and nurturing.
              </p>
            </div>
            <div>
              <img src={sir_teaching} alt="THE BEGINNING" loading="lazy" />
            </div>
          </div>
        </div>
        <hr className={styles.divider_in_about} />
                <div>
          <h2>PROJECTS IN SNEHWAN</h2>
          <div className={styles.about_container}>
            <div>
              <h4>Swami Vivekanand Dnyanalay</h4>
              <p>
Our residential endeavor provides shelter and sponsors the education and nutrition of children who were farm laborers or orphaned by farmers facing financial crises, including those who have tragically taken their own lives due to debt. We extend unwavering support to ensure every educational opportunity, fostering a brighter future for these resilient youngsters.
              </p>
            </div>
            <div>
              <img
                src={swamivivikanand}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Vanchitgram</h4>
              <p>Through Vanchit gram, Snehwan provides complete education and food accessibility to 30 girls belonging to the Nandi Samaj who used to collect trash and beg on the streets of Bhosari city (suburbs of Pune). Along with quality education, all girls are also trained in the traditional dance form of Bharat Natyam.
              </p>
            </div>
            <div>
              <img
                src={vanchitgram}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Kamdhenu Goushala</h4>
              <p>
              At Snehwan, our aim is not only to encourage children to use their education to get good jobs but also to learn practical skills to become entrepreneurs and socially responsible. We seek to realize this through the Kamdhenu Goushala. The cow shelter fosters and serves Desi Gir cows and calves.
              </p>
            </div>
            <div>
              <img
                src={gaushala}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Computer Training Center</h4>
              <p>Snehwan is dedicated to empowering students through the establishment of a comprehensive computer education center. Equipping 150 children with vital technical skills, the center acknowledges the significance of computer literacy in today’s fast-paced world. Irrespective of their residence, even children from neighboring villages are invited to join this free training program, ensuring universal access to modern education.
              </p>
            </div>
            <div>
              <img
                src={computer_lab}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Dr. Kalam Agri Entrepreneur Skill Program</h4>
              <p>At the skill development center, we think kids should go to great schools, follow their ambitions of starting their own businesses, and provide jobs for other people in the community. Children are taught entrepreneurial skills in addition to a thorough understanding of agriculture and its various productions, such as oils, pulses, spices, papad, etc. Furthermore, by taking a holistic approach, we help students develop a sense of social responsibility and how their abilities may benefit the society.
              </p>
            </div>
            <div>
              <img
                src={family6}
                alt="family6"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Dnyanchatra</h4>
              <p>
              Many times, children belonging to rural areas or those who live in slums are forced to drop out of school due to poor financial conditions. Through the Dnyanchatra project, Snehwan has undertaken the complete educational rehabilitation of these children. This initiative also provides rations and complete education to the Adivasi (tribal) female children from Thakarwadi, near Alandi. This is a significant step towards the empowerment of girls in the Adiwasi community.
              </p>
            </div>
            <div>
              <img
                src={Dnyanchatra}
                alt="Dnyanchatra"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Open Library</h4>
              <p>It’s been said that “readers are leaders.” With the intention of reviving the reading culture among children, a collection of 15,000 books has been made available to everyone free of charge. India’s first container library is in Snehwan. This is a one-of-a-kind project where knowledge is provided for free and for everyone. A unique feature is the “One Book, One Movie” concept, designed to promote reading among children. When a child completes a book, they are rewarded with an educational movie to watch on weekends.
              </p>
            </div>
            <div>
              <img
                src={Open_Library}
                alt="Open Library"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Mobile Hospital</h4>
              <p>The humanitarian endeavor of these mobile health camps is to close the healthcare disparity in our neighborhood. These camps serve the impoverished people living on the streets by providing them with both immediate medical attention and education about preventive healthcare practices. In addition to addressing current health issues, this holistic approach aims to make a lasting difference by promoting a culture of wellbeing and health consciousness in our community. Through these efforts, we strive to empower individuals to take charge of their health and build a resilient, healthier society.
              </p>
            </div>
            <div>
              <img
                src={Mobile_hospital}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Savitri Women Empowerment Center</h4>
              <p>Savitri Women Empowerment Center marks a significant milestone in our commitment to fostering independence. Complementing this initiative, we have also launched a sewing machine training center as part of our ongoing efforts to equip individuals with practical skills. The program aims to empower woman with valuable sewing and tailoring skills, promoting self-sufficiency and entrepreneurship. By nurturing self-reliance, we enhance their professional potential and pave the way for a more sustainable future. These initiatives, including the Savitri Women Empowerment Center, embody our dedication to holistic development, ensuring that each child is well-prepared for a brighter and more independent tomorrow.
              </p>
            </div>
            <div>
              <img
                src={Savitri_Woomen_Power}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Education for Elders</h4>
              <p>At Snehwan, we are thrilled to announce an empowering educational initiative aimed at our esteemed elders and staff members. Recognizing the importance of continuous learning, we are providing a unique opportunity for them to enhance their skills, starting with basic English education.
              </p>
              <p>By offering this educational opportunity, we aspire to uplift and empower every individual within our community. It’s not just about learning a language; it’s about fostering a culture of growth, self-improvement, and embracing new possibilities. Together, we’re building a brighter future through education.</p>
            </div>
            <div>
              <img
                src={Education_For_Elders}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Global Classroom</h4>
              <p>Global perspective to our educational initiatives. Over the past 6 months different global volunteers such as, Terezie from the Czech Republic, Reto Nagatake from Japan, Nathan Rono from Kenya, Mahendri Hemachandra from Sri Lanka and Fuka from Japan dedicated their time and expertise to the children at Snehwan. Their presence not only enhanced cultural exchange within our community but also offered the children a unique opportunity to learn from individuals with diverse backgrounds. 
              </p>
            </div>
            <div>
              <img
                src={Global_Classroom}
                alt="Global_Classroom"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Sugar School</h4>
              <p>The Sugar School Project by Snehwan caters to the children of sugarcane workers, educating and caring for them during the 4-5 months when their parents are occupied in sugarcane cutting. Since these children, unable to stay home, join their parents and travel, Snehwan initiates the “sugar school” to educate them on-site. In this project, Snehwan provides educational resources, food, and blankets. This initiative also involves older children from Snehwan in teaching and supporting the visiting children. Over two years, the project has started, offering education, warmth, and care to these children in need.
              </p>
            </div>
            <div>
              <img
                src={Sugar_School}
                alt="Sugar_School plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <hr className={styles.divider_in_about} />
        <div>
          <h2>OUR FACILITIES</h2>
          <div className={styles.about_container}>
            <div>
              <h4>Container Library</h4>
              <p>The Container Library at Snehwan, adorned with 15,000 books, was a generous gift from the Bookwallah Organization in Chicago.
              </p>
              <p>More than mere shelves of stories, this exceptional library serves as a cultural bridge through literature, being the first of its kind in India. It introduces children to diverse narratives, fostering connections with varied cultures and ideas through the captivating art of storytelling.</p>
              <p>Beyond its vast book collection, the library becomes a gateway for language exploration. By engaging primarily with English books, children at Snehwan delve into the language, expanding their linguistic horizons while delighting in the wonders of reading.</p>
            </div>
            <div>
              <img
                src={Container_Lib}
                alt="Container_Lib"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Music Room</h4>
              <p>The Music Room, which was built in 2022, stands as a hub for musical exploration and learning, catering specifically to the musical development of the children under its care. Led by skilled instructors, the Music Room fosters an environment where the magic of music comes alive. Through vocal coaching and instrumental training—particularly in tabla and harmonium—the children are introduced to various musical forms such as Bhavgeets, Abhangas, and Goulans.
              </p>
            </div>
            <div>
              <img
                src={Music_room}
                alt="Music_room"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Computer Lab</h4>
              <p>The Computer Lab at Snehwan stands as a gateway to the contemporary world, empowering children with essential digital literacy and proficiency. It’s more than just a room with computers; it’s a space where young minds embark on a journey of technological exploration and discovery.
              </p>
              <p>Equipped with modern technology, this lab serves as a hub for customized lessons tailored to introduce our young students to various software programs like PowerPoint and Excel. Beyond acquiring fundamental office skills, the curriculum extends into a diverse array of academic and personal applications, fostering a well-rounded skill set.</p>
            </div>
            <div>
              <img
                src={Computer_lab}
                alt="Music_room"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Sewing Machine Training Centre</h4>
              <p>To foster self-reliance among the women in the community, Snehwan introduced an innovative Sewing Machine Training Center. Named in honor of Gnyanjyoti Savitribai Phule, this center symbolizes empowerment and entrepreneurship.
              </p>
              <p>Inaugurated amidst esteemed dignitaries, including the honorable Dr. Raghunath Mashelkar, the center aims to equip local women with skills in garment-making and entrepreneurship. Notably, it extends its reach beyond Snehwan, welcoming Bengali families from adjacent nurseries, where 15 to 20 women engage in learning sessions under the guidance of our skilled trainer.</p>
            </div>
            <div>
              <img
                src={Sewing_machine}
                alt="Sewing_machine"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Physics and Chemistry Labs</h4>
              <p>The physics and chemistry labs are dynamic spaces within our educational institution, equipped with state-of-the-art facilities and cutting-edge instruments. In the physics lab, students engage in hands-on experiments, exploring fundamental principles such as mechanics, electricity, and optics. The chemistry lab, on the other hand, provides a platform for students to conduct chemical analyses, synthesize compounds, and delve into the intricacies of molecular reactions. Both labs prioritize safety measures while encouraging an immersive learning experience. Our students are currently engaged in hands-on activities within the lab, utilizing the provided instruments.
              </p>
            </div>
            <div>
              <img
                src={Physics_chem_lab}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Container Classroom</h4>
              <p>In 2022, Snehwan introduced two state-of-the-art Container Classrooms, meticulously equipped with desks, chairs, and whiteboards—a haven for education. These purpose-built spaces stand as the epitome of ideal study environments for the children.
              </p>
              <p>Designed to foster a culture of learning, these classrooms witness the diligent pursuit of knowledge by the children at Snehwan. Every morning and even on weekends, the eager minds gather here or in the library, using these spaces to cultivate their learning habits.</p>
            </div>
            <div>
              <img
                src={Container_classroom}
                alt="Container_classroom"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Art & Craft Lab</h4>
              <p>At Snehwan, the belief in the significance of art and creativity stands on par with academic pursuits. The Art and Craft Lab serves as an expansive canvas for children to express their creativity freely and passionately.
              </p>
              <p>This innovative lab offers a myriad of painting and crafting tools, providing a diverse spectrum of artistic mediums. From traditional painting supplies to tools used in electrician, masonry, carpentry, and even cosmetics, this space fosters an environment where imagination knows no bounds.</p>
            </div>
            <div>
              <img
                src={Art_and_craft}
                alt="Art_and_craft"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <hr className={styles.divider_in_about} />
        <div>
          <h2>WHAT WE DO</h2>
          <div className={styles.about_container}>
            <div>
              <h4>One Book, One Movie!</h4>
              <p>To improve our children’s mental health and knowledge, Snehwan has created a library that hosts 15000 books spanning various subjects. We also implement some special programs that encourage children to read, as well as enjoy themselves.
              </p>
              <p>Every week, children are shown a movie that has a moral, but there is a catch! It is mandatory to read a book for those who want to watch the movie. This exercise has not only greatly increased reading among the children, but has also inspired many of them to keep diaries and even write poems.</p>
            </div>
            <div>
              <img
                src={One_movie_one_book}
                alt="One_movie_one_book"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Inspirational Visit</h4>
              <p>At Snehwan, we value fostering the will to pursue dreams as well as the dreams themselves. Through engaging conversations with successful people from a variety of backgrounds, our kids learn important lessons about the transformational potential of perseverance. These experiences give them the confidence that, with hard work and commitment, they can overcome obstacles and succeed in life, in addition to inspiring them to dream big. Snehwan is a home where young minds are shaped to embrace both the high ideals and the laborious work necessary to realize those dreams. It is more than just a place for dreams.
              </p>
              
            </div>
            <div>
              <img
                src={Inspirational_visit}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Computer Knowledge</h4>
              <p>Our kids are taught how to use computers so they can interact with the contemporary world and discover new opportunities. With customized lessons on programs such as PowerPoint and Excel, our young students not only gain basic office skills but also become proficient in a variety of areas related to their academic and personal lives. Beyond the fundamentals of computer use, the curriculum covers emailing, sending texts in Marathi, and responsible and safe internet browsing, enabling students to confidently and competently navigate the digital world.
              </p>
            </div>
            <div>
              <img
                src={Computer_lab}
                alt="Computer_lab"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Art & Craft</h4>
              <p>Incorporating thread greeting, collage making, and other customs into our children’s education not only makes learning more enjoyable but also imbues them with indispensable life skills. This practical training in handicrafts encourages neatness and accuracy in their work while fostering a sense of creativity and aesthetics. Additionally, the exercise improves their ability to focus, providing a comprehensive education that goes beyond textbooks and supports their general cognitive development.
              </p>
            </div>
            <div>
              <img
                src={Art_and_craft_1}
                alt="Art_and_craft_1"
                loading="lazy"
              />
            </div>
          </div>
          
          <div className={styles.about_container}>
            <div>
              <h4>Educational Tour</h4>
              <p>A vital component of our all-encompassing approach to education are these entertaining and instructive tours. Our children’s perspectives are broadened and their understanding of the world around them is cultivated by the experiences they get from visiting colleges, small businesses, diverse companies, and forts. These travels not only offer a change of pace but also greatly advance their practical knowledge by revealing insights into diverse fields and promoting an inquiry-based approach to learning.
              </p>
            </div>
            <div>
              <img
                src={Educational_tour}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Ringan (Circle)</h4>
              <p>This activity aims to increase the confidence of our children by encouraging them to share their thoughts and ideas. Group discussions are carried out with all the children seated in a circle. Each child is given a chance to stand in front of the group and speak for two minutes on a book he/she has read.
              </p>
              <p>Sometimes, a particular subject is given for everyone to express their spontaneous opinion about it. Doing so results in increasing the confidence and creativity of the children, simultaneously reducing their sense of shyness.</p>
            </div>
            <div>
              <img
                src={Circle_ringan}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Teaching Social Responsibility</h4>
              <p>We want our children to become responsible citizens of the society. To this end, we take special efforts to inculcate a feeling of social commitment and support for the needs of community in their minds.
              </p>
              <p>At Snehwan, each child takes care of his/her friends and helps them with their work. Along with many skills, our children are taught the importance of labor and how to become knowledgeable and accountable. We celebrate Rakshabandhan; wherein the children create and sell rakhis, and buy new clothes for sisters/girls from their own money. Thus, this program has greatly increased the social awareness and progress of our children.</p>
            </div>
            <div>
              <img
                src={Skill_Dev}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Live Together, Eat Together</h4>
              <p>We support the principles outlined in our National pledge, which views every student at Snehwan as an essential member of our larger family, by embracing the spirit of unity and fraternity. Ashok, Archana, and all the kids gather to share meals, fostering a sense of community and fostering an atmosphere that encourages love, affection, and support from one another. In addition to feeding their bodies, this communal dining experience feeds their sense of belonging and emphasizes the value of support and camaraderie within the Snehwan family.
              </p>
            </div>
            <div>
              <img
                src={Live_eat_together}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Lessons in Music</h4>
              <p>At Snehwan, we recognize the importance of recreational activities in children’s lives. Ashok’s father, along with one other teacher, gives music lessons to our children. As a result, many of them have become fond of music forms such as Bhavgeets, Abhangas, Goulans and also present them on stage. In addition to vocal training, the children at Snehwan are also privileged to receive lessons in instrumental music, particularly tabla and harmonium. The inclusion of these traditional instruments not only broadens the students’ musical collection but also instills in them a sense of discipline and dedication as they hone their skills.
              </p>
            </div>
            <div>
              <img
                src={Music_live}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Physical and Mental Well-being</h4>
              <p>In Snehwan, Mallakhamb and yoga take center stage as transformative practices for physical and mental well-being. Guided by skilled instructors, these ancient disciplines become more than exercises—they are pathways to self-discovery and holistic health. Mallakhamb, with its dynamic use of a vertical pole, builds strength and flexibility, while yoga, through postures and breathwork, nurtures inner calm and mindfulness. Rooted in the ethos of Snehwan, these practices go beyond physical prowess, offering a transformative journey that aligns with the organization’s commitment to empowering individuals through comprehensive well-being and fostering a sense of community.
              </p>
            </div>
            <div>
              <img
                src={yoga}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>For the Children, By the Children</h4>
              <p>Snehwan instills vital skills like leadership and responsibility in children by actively involving them in various aspects of the institute’s functioning. Each child holds age-specific roles, managing different departments like finance, stores, and the kitchen. For instance, a 9th-grade student oversees the library, and an 8th-grade student manages the Computer Lab. This setup ensures direct access to department leaders, empowering children to take charge and fostering their independence.
              </p>
              <p>This structure not only encourages accountability but also allows children to support one another, nurturing a sense of leadership and autonomy in their educational journey at Snehwan.</p>
            </div>
            <div>
              <img
                src={kitchen}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles.about_container}>
            <div>
              <h4>Eco-Friendly Living</h4>
              <p>Solar Power Station – Snehwan is a sustainable organization that entirely runs on solar energy. The energy needs of the organization are generated on-site with solar panels. Biogas Generation Plant – Gas used for cooking is generated through the Biogas plant at Snehwan, which produces 3000 liters of gas each day.
              </p>
            </div>
            <div>
              <img
                src={Eco_friendly}
                alt="children plyaing carem"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
