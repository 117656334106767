
import React from 'react';
import styles from './styles/awardrm.module.css';
import photos from '../../assets/gallery/PHOTO.png';
import videos from '../../assets/gallery/VIDEOS.png';
import media from '../../assets/gallery/MEDIA.png';
import { useNavigate } from 'react-router-dom';
const GalleryRM = () => {
    const navigate = useNavigate();
    const goPathDetails = (path="/photos")=>{
      navigate(path);
    }
  return (
    <div className={styles.awardrm}>
      <div
        className={styles.award_item}
        onClick={() => goPathDetails('photos')}
      >
        <img
          src={photos}
          alt="photos"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >PHOTOS</p>
      </div>

      <div
        className={styles.award_item}
        onClick={() => goPathDetails('videos')}
      >
        <img
          src={videos}
          alt="Video content"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >VIDEOS</p>
      </div>
      <div
        className={styles.award_item}
        onClick={() => goPathDetails('media-coverage')}
      >
        <img
          src={media}
          alt="media"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >MEDIA</p>
      </div>
    </div>
  );
};

export default GalleryRM;
