
import React from 'react';
import styles from './styles/awardrm.module.css';
import compnay from '../../assets/get_involved/COMPANY.jpeg';
import csr from '../../assets/get_involved/CSR.jpeg';
import volunteer from '../../assets/get_involved/VOLUNTEER.jpeg';
import { useNavigate } from 'react-router-dom';
const GetInvolvedOptions = () => {
    const navigate = useNavigate();
    const goPathDetails = (path="/volunteer")=>{
      navigate(path);
    }
  return (
    <div className={styles.awardrm}>
      <div
        className={styles.award_item}
        onClick={() => goPathDetails('volunteer')}
      >
        <img
          src={volunteer}
          alt="volunteer"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >VOLUNTEER</p>
      </div>

      <div
        className={styles.award_item}
        onClick={() => goPathDetails('compnay')}
      >
        <img
          src={compnay}
          alt="compnay"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >FRIENDS</p>
      </div>
      <div
        className={styles.award_item}
        onClick={() => goPathDetails('csr')}
      >
        <img
          src={csr}
          alt="csr"
          className={styles.award_categary_icon}
          loading="lazy"
        />
        <p >COMPANY</p>
      </div>
    </div>
  );
};

export default GetInvolvedOptions;
