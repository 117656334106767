import React, { useState } from 'react';
import styles from '../styles/getinvolved.module.css';

const GetInvolvedForm = ({
  formData,
  handleChange,
  handleSubmit,
  captcha,
  setCaptchaInput,
  isSubmitting,
  statusMessage,
}) => {
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleProjectChange = (event) => {
    const { name, checked } = event.target;
    setSelectedProjects((prev) =>
      checked ? [...prev, name] : prev.filter((project) => project !== name)
    );
  };

  const handleButtonClick = () => {
    const formWithProjects = {
      ...formData,
      projects: selectedProjects.join(', '),
    };
    handleSubmit(formWithProjects);
  };

  return (
    <div className={styles.getInvolved}>
      <div className={styles.leftformSection}>
        <div className={styles.partformGroup}>
          <label className={styles.heading}>BE THE PART OF OUR CIRCLE</label>
        </div>
        <div className={styles.formPart}>
          <div className={styles.partformGroup}>
            <label className={styles.formLabel} htmlFor="name">
              Name -
            </label>
            <input
              className={styles.input}
              value={formData.name}
              type="text"
              id="name"
              name="name"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.partformGroup}>
            <label className={styles.formLabel} htmlFor="email">
              Email -
            </label>
            <input
              className={styles.input}
              value={formData.email}
              type="email"
              id="email"
              name="email"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.partformGroup}>
            <label className={styles.formLabel} htmlFor="contact">
              Contact -
            </label>
            <input
              className={styles.input}
              value={formData.contact}
              type="text"
              id="contact"
              name="contact"
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.partformGroup}>
            <label className={styles.formLabel} htmlFor="address">
              Address -
            </label>
            <textarea
              className={styles.textarea}
              value={formData.address}
              id="address"
              name="address"
              rows="3"
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className={styles.partformGroup}>
            <label className={styles.formLabel} htmlFor="message">
              Message -
            </label>
            <textarea
              className={styles.textarea}
              value={formData.message}
              id="message"
              name="message"
              rows="2"
              onChange={handleChange}
              required
            ></textarea>
          </div>
        </div>
      </div>

      <div className={styles.projectSection}>
        <label className={styles.heading}>PROJECT CHOICE</label>
        <div className={styles.projectChoices}>
          <div className={styles.projectList}>
            {[
              'Health',
              'Education',
              'Rehabilitation',
              'Awareness Programs',
              'Fund Raising',
              'Management',
              'Public Relation',
            ].map((project, index) => (
              <div className={styles.listChoices} key={index}>
                <input
                  type="checkbox"
                  id={project.toLowerCase().replace(' ', '')}
                  name={project}
                  className={styles.checkBox}
                  onChange={handleProjectChange}
                />
                <label htmlFor={project.toLowerCase().replace(' ', '')}>
                  {project}
                </label>
              </div>
            ))}
          </div>
        </div>
        {statusMessage.message && (
          <div className={styles.verticalGroup}>
            <p
              className={`${styles.statusMessage} ${
                statusMessage.type === 'success' ? styles.success : styles.error
              }`}
            >
              {statusMessage.message}
            </p>
          </div>
        )}
        <div className={styles.verticalGroup}>
          <div className={styles.captchaGroup}>
            <p>
              {captcha.num1} + {captcha.num2} =
            </p>
          </div>
          <div>
            <input
              className={styles.captchaInput}
              type="text"
              onChange={(e) => setCaptchaInput(e.target.value)}
              required
            />
          </div>
          <div >
          <button className={styles.submitButton} onClick={handleButtonClick}  disabled={isSubmitting}>
          {isSubmitting ? 'sending...' : 'submit'}
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GetInvolvedForm;
