import React from "react";
import styles from './styles/common.module.css';

const InternationalDonation = () => {
    return (
        <div className={styles.internationaldonationcontainer}>
            <h2>DONATION FROM OVERSEAS</h2>
            <div className={styles.donationintro}>
                <p>
                    Snehwan has a valid FCRA license granted by the Ministry of Home Affairs, New Delhi. We can accept donations in any currency from any country. These donations must be credited into our FCRA a/c:
                </p>
                <div>
                    <label>
                        FCRA Registration No:
                    </label>
                    <p> 083930758</p>
                </div>
            </div>

            <div className={styles.donationdetails}>
                <h3>The Bank details of the FCRA a/c are as follows:</h3>
                <div className={styles.detailRow}>
                    <label>Account Name:</label>
                    <p>SNEHWAN</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Bank Name:</label>
                    <p>
                        STATE BANK OF INDIA</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Branch Name:</label>
                    <p>
                        NEW DELHI MAIN BRANCH</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Account Number:</label>
                    <p>
                        39985093463</p>
                </div>
                <div className={styles.detailRow}>
                    <label>MICR Number:</label>
                    <p>
                        110002087</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Swift Code:</label>
                    <p>
                        SBININBB104</p>
                </div>
                <div className={styles.detailRow}>
                    <label>IFSC Code:</label>
                    <p>
                        SBIN0000691</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Address:</label>
                    <p>FCRA Cell, 4th Floor, State Bank Of India, New Delhi Main Branch, 11, Sansad Marg, New Delhi – 110001</p>
                </div>
            </div>
        </div>
    );
};

export default InternationalDonation;
