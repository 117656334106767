import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import ContactRM from '../components/pagedata/ContactRM';
import { useLocation } from 'react-router-dom';

const Contact = () => {
  const [readMore, setReadMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setReadMore(false);
  }, [location]);
  return (
    <div id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <MainDataShowup childComponent={ContactRM} />
          </motion.div>
        ) : (
          <MainInfoComponent
            heading="contact snehwan"
            subheading="Your Voice Matters !"
            para={
              <>
                <strong>Have questions or feedback? 
                  We’d love to hear from you! </strong>
                If you want to share your thoughts, or have a general inquiry, our team is ready to assist you.
              </>
            }
            readMore="Fill in your details"
            onReadMore={() => setReadMore(true)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
export default Contact;
