import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import AwardRM from '../components/pagedata/AwardRM';
import { Routes, Route, useNavigate } from 'react-router-dom';
import State from '../components/pagedata/awards/State';
import National from '../components/pagedata/awards/National';
import International from '../components/pagedata/awards/International';

const Award = () => {
  const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    setReadMore(true); 
    navigate(path);
  };
  return (
    <div id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <Routes>
              <Route path="/" element={<MainDataShowup childComponent={AwardRM} />} />
              <Route path="state" element={<MainDataShowup childComponent={State} />} />
              <Route path="india" element={<MainDataShowup childComponent={National} />} />
              <Route path="international" element={<MainDataShowup childComponent={International} />} />
            </Routes>
          </motion.div>
        ) : (
          <MainInfoComponent
            heading="our awards"
            subheading="Celebrating Our Achievements"
            para={
              <>
                At <strong>Snehwan</strong>, we believe that recognition is not just about accolades; it’s a testament to the <strong>dedication and hard work of our team, volunteers, and supporters. </strong>
                Our awards reflect our commitment to making a meaningful impact in the lives of those we serve and inspire us to continue striving for excellence.
                As we celebrate these achievements, we remain focused on our vision for the future. Thank you for being an essential part of our story and for <strong>helping us reach new heights!</strong>
              </>
            }
            readMore="Explore awards"
            onReadMore={() => handleNavigation('/award')}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
export default Award;
