import React, { useState, useEffect, useMemo } from 'react';
import styles from "../styles/gallerysubmodule.module.css";
import GalleryCard from '../../common/GalleryCard';
import { motion } from 'framer-motion';

const Friends = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    fetch('/data/friends.json')
      .then((response) => response.json())
      .then((data) => {
        setPhotos(data.friends);
      })
      .catch((error) => console.error('Error fetching photos:', error));
  }, []);

  const memoizedPhotos = useMemo(() => photos, [photos]);
  const loadingAnimation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5, delay: 0.2 },
  };

  return (
    <div className={styles.photos}>
    <h4 className={styles.friends_of_snehwan_title}>FRIENDS OF SNEHWAN</h4>
      {memoizedPhotos.length > 0 ? (
        <motion.div
          className={styles['photo-gallery']}
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
          }}
        >
          {memoizedPhotos.map((photo, index) => (
            <div key={index} >
              <GalleryCard photo={photo} />
            </div>
          ))}
        </motion.div>
      ) : (
        <motion.p
          initial="hidden"
          animate="visible"
          variants={loadingAnimation}
        >
          Loading photos...
        </motion.p>
      )}
    </div>
  );
};

export default Friends;