import React from 'react';
import MainDataShowup from '../components/common/MainDataShowup';
import PrivacyAndTerms from '../components/pagedata/PrivacyAndTerms';

const Privacy = () => {
  return <div>
     <MainDataShowup childComponent={PrivacyAndTerms} />
  </div>;
};

export default Privacy;
