import React, { useState } from "react";
import emailjs from "emailjs-com";
import GetInvolvedForm from "./formdata/GetInvolvedForm";

const GetInvolvedRM = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' }); 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    message: "",
    projects: [],
  });

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (data) => {
    if (
      !data.name.trim() ||
      !data.email.trim() ||
      !data.contact.trim() ||
      !data.address.trim() ||
      !data.message.trim()
    ) {
      setStatusWithTimeout({ type: 'error', message: 'Please fill in all required fields.' });
      return;
    }
    if (data.projects.length === 0) {
      setStatusWithTimeout({ type: 'error', message: 'Please select at least one project.' });
      return;
    }
    if (parseInt(captchaInput, 10) !== captcha.result) {
      setStatusWithTimeout({ type: 'error', message: 'Incorrect captcha. Please try again.' });
      setCaptcha(generateCaptcha()); 
      return;
    }

    const {
      REACT_APP_EMAILJS_SERVICE_ID,
      REACT_APP_EMAILJS_TEMPLATE_ID,
      REACT_APP_EMAILJS_USER_ID,
    } = process.env;
    if (!REACT_APP_EMAILJS_SERVICE_ID || !REACT_APP_EMAILJS_TEMPLATE_ID || !REACT_APP_EMAILJS_USER_ID) {
      setStatusWithTimeout({ type: 'error', message: 'Email configuration error. Please contact support.' });
      return;
    } 
    setIsSubmitting(true);
    const templateParams = {
      ...data,
      to_name: "Snehwan",
      from_name: data.name,
    };
    
    emailjs
      .send(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        REACT_APP_EMAILJS_USER_ID
      )
      .then(() => {
        setFormData({
          name: "",
          email: "",
          contact: "",
          address: "",
          message: "",
          projects: [],
        });
        setCaptcha(generateCaptcha());
        setCaptchaInput("");
        setStatusWithTimeout({ type: 'success', message: 'Your message has been sent successfully.' });
      })
      .catch((error) => {
        console.error("EmailJS Error:", error);
        setStatusWithTimeout({ type: 'error', message: 'Failed to send your message. Please try again later.' });
        
      }).finally(()=>{
        setIsSubmitting(false);
      });
  };

  function generateCaptcha() {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    return { num1, num2, result: num1 + num2 };
  }
  function setStatusWithTimeout(status) {
    setStatusMessage(status);
    setTimeout(() => {
      setStatusMessage({ type: '', message: '' });
    }, 3000); 
  }

  return (
    <div>
      <GetInvolvedForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        captcha={captcha}
        setCaptchaInput={setCaptchaInput}
        isSubmitting={isSubmitting}  
        statusMessage={statusMessage}
      />
    </div>
  );
};

export default GetInvolvedRM;
