import React from 'react';
import { motion } from 'framer-motion';
import styles from './styles/maindata.module.css';
const MainDataShowup = ({ childComponent: ChildComponent }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.5 }}
    >
      <div className={styles.main_data_show_up}>
        <div className={styles.main_data_gap}>
          {ChildComponent && <ChildComponent />}
        </div>
      </div>
    </motion.div>
  );
};

export default MainDataShowup;