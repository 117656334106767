import React from 'react';
import styles from './styles/common.module.css';

const DonationFromIndia = () => {
    return (
        <div className={styles.donationcontainer}>
            <h2>DONATION FROM INDIA</h2>
            <div className={styles.donationdetails}>
                <div className={styles.detailRow}>
                    <label>Account Name:</label>
                    <p>Snehwan</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Account Type:</label>
                    <p>Current</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Account Number:</label>
                    <p>35517151681</p>
                </div>
                <div className={styles.detailRow}>
                    <label>IFSC Code:</label>
                    <p>SBIN0005923</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Bank Name:</label>
                    <p>State Bank of India</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Branch:</label>
                    <p>Pimpri Town</p>
                </div>
                <div className={styles.detailRow}>
                    <label>Address:</label>
                    <p> Dr. Ambedkar Road, Pimpri Colony, Pune - 411017</p>
                </div>
            </div>

            <div className={styles.donationcheque}>
                <h3>Send a Cheque</h3>
                <p>
                    Please make cheques payable to <strong>‘Snehwan’</strong> and post to:
                </p>
                <p>
                    Snehwan, Koyali Forest, Koyali tarf Chakan, Tq. Khed, Dist. Pune Maharashtra - 410501
                </p>
            </div>

            <div className={styles.taxexemption}>
                <p>
                    Tax exemption are available to all Indian tax payers under 80G of the Income Tax Act (1961).
                </p>
                <p>
                    To receive your tax receipt please email <a href="mailto:snehwan@yahoo.in">snehwan@yahoo.in</a> with details of your Name, PAN, payment made.
                </p>
            </div>
        </div>
    );
};
export default DonationFromIndia;
