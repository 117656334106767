import React, { useEffect, useState } from 'react'
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import DocumentRM from '../components/pagedata/DocumentRM';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
const Document = () => {
  const [readMore, setReadMore] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setReadMore(false);
  }, [location]);
  return (
    <div id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <MainDataShowup childComponent={DocumentRM} />
          </motion.div>
        ) : (
          <MainInfoComponent
            heading="our documents"
            subheading="Empowering Change Through Knowledge"
            readMore="read more"
            onReadMore={() => setReadMore(true)}
            para={
              <>

                Welcome to our Document section, where <strong>information meets inspiration. </strong>
                Here, you will find a wealth of information such as <strong>Snehwan Warta</strong> (monthly newsletter) which talks about success stories and educational materials that highlights our mission and the impact of our work at Snehwan.
                You'll also find <strong>certificates and legal documents issued by Government of India. </strong>
              </>

            }

          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default Document;