import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import GalleryRM from '../components/pagedata/GalleryRM';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Photos from '../components/pagedata/gallery/Photos';
import Media from '../components/pagedata/gallery/Media';
import Video from '../components/pagedata/gallery/Video';
const Gallery = () => {
  const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    setReadMore(true); 
    navigate(path);
  };
  return (
    <div id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <Routes>
              <Route path="/" element={<MainDataShowup childComponent={GalleryRM} />} />
              <Route path="photos" element={<MainDataShowup childComponent={Photos} />} />
              <Route path="videos" element={<MainDataShowup childComponent={Video} />} />
              <Route path="media-coverage" element={<MainDataShowup childComponent={Media} />} />
            </Routes>
          </motion.div>
        ) : (
          <MainInfoComponent
            heading="our gallery"
            subheading="Inspiring Change Through Compassion"
            readMore="View gallery"
            onReadMore={() => handleNavigation('/gallery')}
            para={
              <>
                  Welcome to our gallery, where every image tells a <strong>story of hope, resilience, and transformation. </strong> 
                  Here, you will find snapshots of the lives we touch and the communities we uplift. Each photograph captures the <strong>spirit of our mission</strong> and the impact of our work, 
                  showcasing the incredible journeys of individuals and families who have benefited from our programs. Together, we are not just making a difference; <strong>we are changing lives. </strong> 
                  Thank you for being a part of this journey!
              </>
            }
            
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Gallery;
