import React from 'react';
import styles from './styles/maincomponent.module.css';

const MainInfoComponent = ({ heading, subheading, para, onReadMore ,readMore}) => {
  const handleReadMore = () => {
    onReadMore()

    setTimeout(() => {
      const offset = -100; 
      const topPosition = window.scrollY + offset;
      window.scrollTo({
        top: topPosition > 0 ? 0 : topPosition, 
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <div
      className={styles.main_info_component} >
      <div>
        <div>
          <h1>{heading}</h1>
        </div>
        <div>
          <h4>{subheading}</h4>
        </div>
        <div>
          <div className={styles.main_para}>
            <p>
              {para}
            </p>
          </div>
          <p className={styles.readmore} onClick={handleReadMore}>
            {readMore}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainInfoComponent;