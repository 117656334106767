import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ContactForm from './formdata/ContactForm';
const ContactRM = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' }); 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    address: '',
    message: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, contact, address, message } = formData;
    if (!name.trim() || !email.trim() || !contact.trim() || !address.trim() || !message.trim()) {
      setStatusMessage({ type: 'error', message: 'Please fill in all required fields.' });
      return;
    }

    const { REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, REACT_APP_EMAILJS_USER_ID } =
      process.env;

    if (!REACT_APP_EMAILJS_SERVICE_ID || !REACT_APP_EMAILJS_TEMPLATE_ID || !REACT_APP_EMAILJS_USER_ID) {
      setStatusMessage({ type: 'error', message: 'Email configuration error. Please contact support.' });
      return;
    } 
    setIsSubmitting(true);

    const templateParams = {
      ...formData,
      to_name: 'Snehwan',
      from_name: name,
    };

    try {
      await emailjs.send(
        REACT_APP_EMAILJS_SERVICE_ID,
        REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        REACT_APP_EMAILJS_USER_ID
      );
      setStatusMessage({ type: 'success', message: 'Your message has been sent successfully.' });
      setFormData({ name: '', email: '', contact: '', address: '', message: '' });
    } catch (error) {
      console.error('Failed to send email:', error);
      setStatusMessage({ type: 'error', message: 'Failed to send your message. Please try again later.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <ContactForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} isSubmitting={isSubmitting}  statusMessage={statusMessage} />
    </div>
  );
};

export default ContactRM;