import React from "react";
import calender from "../../assets/document/icons/calendar-icon.png";
import legal from "../../assets/document/icons/legal-icon.png";
import brochure from "../../assets/document/icons/brochure-icon.png";
import styles from "./styles/document.module.css";
 
const DocumentRM = () => {
  const handleDocumentClick = (month) => {
    const pdfPaths = {
      JANUARY: "/assets/documents/monthly_newsletter/SNEHWAN-VARTA_Jan_2024.pdf",
      FEBRUARY: "/assets/documents/monthly_newsletter/SNEHWAN-VARTA_Feb_2024.pdf",
      MARCH: "/assets/documents/monthly_newsletter/SNEHWAN-VARTA_March_2024.pdf",
      APRIL: "/assets/documents/monthly_newsletter/SNEHWAN-VARTA_April_2024_Final_1.pdf",
      MAY: "/assets/documents/monthly_newsletter/SNEHWAN-VARTA_May_2024.pdf",
      "FCRA registration certificate": "/assets/documents/legal_documents/FCRA-Registration-Certificate.pdf",
      "csr1_mca_certificate": "/assets/documents/legal_documents/MCA-Certificate-CSR-1.pdf",
      "80_G": "/assets/documents/legal_documents/80G_New.pdf",
      "80G_New": "/assets/documents/legal_documents/12A_New.pdf",
      "Section_12A": "/assets/documents/legal_documents/Section_12A.pdf",
 
      english: "/assets/documents/company_brochure/Snehwan-Brochure_English_7-4-2024.pdf",
      marathi: "/assets/documents/company_brochure/Snehwan-Marathi-Brochure.pdf",
    };
 
 
    const pdfPath = pdfPaths[month];
    if (pdfPath) {
      window.open(pdfPath, "_blank");
    }
  };
 
  return (
    <div className={styles.document_rm}>
      <div className={styles.documet_row}>
        <div className={styles.documet_title}>
          <div>
            <h4>
              MONTHLY
              <br /> NEWSLETTER
            </h4>
          </div>
          <img src={calender || "/placeholder.svg"} alt="Calender Icon" loading="lazy" />
        </div>
        <div></div>
        <div className={styles.links_details}>
          <div>
            <p onClick={() => handleDocumentClick("JANUARY")} style={{ cursor: "pointer" }}>
              JANUARY
            </p>
            <p onClick={() => handleDocumentClick("JUNE")} style={{ cursor: "pointer" }}>
              JUNE
            </p>
            <p onClick={() => handleDocumentClick("NOVEMBER")} style={{ cursor: "pointer" }}>
              NOVEMBER
            </p>
          </div>
          <hr />
          <div>
            <p onClick={() => handleDocumentClick("FEBRUARY")} style={{ cursor: "pointer" }}>
              FEBRUARY
            </p>
            <p onClick={() => handleDocumentClick("JULY")} style={{ cursor: "pointer" }}>
              JULY
            </p>
            <p onClick={() => handleDocumentClick("DECEMBER")} style={{ cursor: "pointer" }}>
              DECEMBER
            </p>
          </div>
          <hr />
          <div>
            <p onClick={() => handleDocumentClick("MARCH")} style={{ cursor: "pointer" }}>
              MARCH
            </p>
            <p onClick={() => handleDocumentClick("AUGUST")} style={{ cursor: "pointer" }}>
              AUGUST
            </p>
            <p>&nbsp;</p>
          </div>
          <hr />
          <div>
            <p onClick={() => handleDocumentClick("APRIL")} style={{ cursor: "pointer" }}>
              APRIL
            </p>
            <p onClick={() => handleDocumentClick("SEPTEMBER")} style={{ cursor: "pointer" }}>
              SEPTEMBER
            </p>
            <p>&nbsp;</p>
          </div>
          <hr />
          <div>
            <p onClick={() => handleDocumentClick("MAY")} style={{ cursor: "pointer" }}>
              MAY
            </p>
            <p onClick={() => handleDocumentClick("OCTOBER")} style={{ cursor: "pointer" }}>
              OCTOBER
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.documet_row}>
        <div className={styles.documet_title}>
          <div>
            <h4>
              LEGAL <br /> DOCUMENTS
            </h4>
          </div>
          <img src={legal || "/placeholder.svg"} alt="Legal Icon" loading="lazy" />
        </div>
        <div></div>
        <div className={styles.links_details}>
          <div>
            <p onClick={() => handleDocumentClick("FCRA registration certificate")} style={{ cursor: "pointer" }}>
              FCRA registration certificate
            </p>
          </div>
          <hr />
          <div>
            <p onClick={() => handleDocumentClick("csr1_mca_certificate")} style={{ cursor: "pointer" }}>
              csr1 mca certificate
            </p>
          </div>
          <hr />
          <div>
          
            <p onClick={() => handleDocumentClick("80_G")} style={{ cursor: "pointer" }}>
            80G New
 
            </p>
       
            <p onClick={() => handleDocumentClick("80G_New")} style={{ cursor: "pointer" }}>
            Section 12A
 
            </p>
 
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.documet_row}>
        <div className={styles.documet_title}>
          <div>
            <h4>
              COMPANY <br /> BROCHURE
            </h4>
          </div>
          <img src={brochure || "/placeholder.svg"} alt="Brochure Icon" loading="lazy" />
        </div>
        <div></div>
        <div className={styles.links_details}>
          <div>
            <p onClick={() => handleDocumentClick("english")} style={{ cursor: "pointer" }}>
              english
            </p>
          </div>
          <hr />
          <div>
            <p onClick={() => handleDocumentClick("marathi")} style={{ cursor: "pointer" }}>
              marathi
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default DocumentRM;