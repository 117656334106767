import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MainInfoComponent from '../components/common/MainInfoComponent';
import MainDataShowup from '../components/common/MainDataShowup';
import GetInvolvedRM from '../components/pagedata/GetInvolvedRM';
import { Routes, Route, useNavigate } from 'react-router-dom';
import GetInvolvedOptions from '../components/pagedata/GetInvolvedOptions';
import Friends from '../components/pagedata/getInvolved/Friends';
import Csrpage from '../components/pagedata/getInvolved/Csrpage';
const GetInvolved = () => {
  const [readMore, setReadMore] = useState(false);
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    setReadMore(true); 
    navigate(path);
  };

  return (
    <div id="mainDataShowup">
      <AnimatePresence mode="wait">
        {readMore ? (
          <motion.div
            key="MainDataShowup"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
          >
            <Routes>
              <Route path="/" element={<MainDataShowup childComponent={GetInvolvedOptions} />} />
              <Route path="volunteer" element={<MainDataShowup childComponent={GetInvolvedRM} />} />
              <Route path="compnay" element={<MainDataShowup childComponent={Friends} />} />
              <Route path="csr" element={<MainDataShowup childComponent={Csrpage} />} />
            </Routes>
          </motion.div>
        ) : (
          <MainInfoComponent
            heading="you get involved"
            subheading="Join Us in Making a Difference!"
            para={
              <>
            
                  Are you ready to be a <strong>catalyst for change</strong>?  At Snehwan,
                  we believe that every helping hand can create a ripple effect of positivity in our children.
                  By becoming a volunteer, you have the opportunity to make a <strong>meaningful impact</strong> in the lives of those <strong>who need it most</strong>.
                  Together, we can <strong>build a brighter future—one act of kindness at a time. </strong> Sign up today and <strong>become a part of our mission!</strong>
               
              </>
            }
            readMore="Join us"
            onReadMore={() => handleNavigation('/get-involved')}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
export default GetInvolved